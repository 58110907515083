const DrawingsFull = [
    {orgPath:require('./Original/DH-Ritual 1 Preparation 2020;22x13;.jpeg'),sm:require('./Small/DH-Ritual 1 Preparation 2020;22x13;.jpeg'),md:require('./Medium/DH-Ritual 1 Preparation 2020;22x13;.jpeg')},
    {orgPath:require('./Original/DH-Ritual 2 Meditation 2020;20x15;.jpeg'),sm:require('./Small/DH-Ritual 2 Meditation 2020;20x15;.jpeg'),md:require('./Medium/DH-Ritual 2 Meditation 2020;20x15;.jpeg')},
    {orgPath:require('./Original/DH-Ritual 3 Chastisement 2020;15x20;.jpeg'),sm:require('./Small/DH-Ritual 3 Chastisement 2020;15x20;.jpeg'),md:require('./Medium/DH-Ritual 3 Chastisement 2020;15x20;.jpeg')},
    {orgPath:require('./Original/DH-Ritual 4 Grieving 2020;24x15;.jpeg'),sm:require('./Small/DH-Ritual 4 Grieving 2020;24x15;.jpeg'),md:require('./Medium/DH-Ritual 4 Grieving 2020;24x15;.jpeg')},
    {orgPath:require('./Original/DH-Peg Legs 2018;23x19;.jpeg'),sm:require('./Small/DH-Peg Legs 2018;23x19;.jpeg'),md:require('./Medium/DH-Peg Legs 2018;23x19;.jpeg')},
    {orgPath:require('./Original/DH-Self-Portrait 1991;10x13.5;.jpeg'),sm:require('./Small/DH-Self-Portrait 1991;10x13.5;.jpeg'),md:require('./Medium/DH-Self-Portrait 1991;10x13.5;.jpeg')},
    {orgPath:require('./Original/DH-Jimmy with a Film Reel 1991;11x15;.jpeg'),sm:require('./Small/DH-Jimmy with a Film Reel 1991;11x15;.jpeg'),md:require('./Medium/DH-Jimmy with a Film Reel 1991;11x15;.jpeg')},
    {orgPath:require('./Original/DH-Viktor 198_;13x18;.jpeg'),sm:require('./Small/DH-Viktor 198_;13x18;.jpeg'),md:require('./Medium/DH-Viktor 198_;13x18;.jpeg')},
    {orgPath:require('./Original/DH-Luciano Orsini 1991;17x24;.jpeg'),sm:require('./Small/DH-Luciano Orsini 1991;17x24;.jpeg'),md:require('./Medium/DH-Luciano Orsini 1991;17x24;.jpeg')},
    {orgPath:require('./Original/DH-Nancy 1992;10x14;.jpeg'),sm:require('./Small/DH-Nancy 1992;10x14;.jpeg'),md:require('./Medium/DH-Nancy 1992;10x14;.jpeg')},
    {orgPath:require('./Original/DH-Fox Pup 198_;14x19;.jpeg'),sm:require('./Small/DH-Fox Pup 198_;14x19;.jpeg'),md:require('./Medium/DH-Fox Pup 198_;14x19;.jpeg')},
    {orgPath:require('./Original/DH-Snoozing 2006;16x12;.jpeg'),sm:require('./Small/DH-Snoozing 2006;16x12;.jpeg'),md:require('./Medium/DH-Snoozing 2006;16x12;.jpeg')},
    {orgPath:require('./Original/DH-Hannah Bemused 1991;27x40;.jpeg'),sm:require('./Small/DH-Hannah Bemused 1991;27x40;.jpeg'),md:require('./Medium/DH-Hannah Bemused 1991;27x40;.jpeg')},
    {orgPath:require('./Original/DH-Naomi Dancing 1991;27x40;.jpeg'),sm:require('./Small/DH-Naomi Dancing 1991;27x40;.jpeg'),md:require('./Medium/DH-Naomi Dancing 1991;27x40;.jpeg')},
    {orgPath:require('./Original/DH-Bend in a River 2017;23x15;.jpeg'),sm:require('./Small/DH-Bend in a River 2017;23x15;.jpeg'),md:require('./Medium/DH-Bend in a River 2017;23x15;.jpeg')},
    {orgPath:require('./Original/DH-Near Arcetri 1992;13x9.5;.jpeg'),sm:require('./Small/DH-Near Arcetri 1992;13x9.5;.jpeg'),md:require('./Medium/DH-Near Arcetri 1992;13x9.5;.jpeg')},
    {orgPath:require('./Original/DH-Valley with Fruit Trees 2017;21x16;.jpeg'),sm:require('./Small/DH-Valley with Fruit Trees 2017;21x16;.jpeg'),md:require('./Medium/DH-Valley with Fruit Trees 2017;21x16;.jpeg')},
    {orgPath:require('./Original/DH-Meadows near Piacenza 2012;11x9.5;.jpeg'),sm:require('./Small/DH-Meadows near Piacenza 2012;11x9.5;.jpeg'),md:require('./Medium/DH-Meadows near Piacenza 2012;11x9.5;.jpeg')},
    {orgPath:require('./Original/DH-Pear Tree by a Canal 2014;13.5x10;.jpg'),sm:require('./Small/DH-Pear Tree by a Canal 2014;13.5x10;.jpg'),md:require('./Medium/DH-Pear Tree by a Canal 2014;13.5x10;.jpg')},
    {orgPath:require('./Original/DH-Via del Podestà 1992;21x11;.jpeg'),sm:require('./Small/DH-Via del Podestà 1992;21x11;.jpeg'),md:require('./Medium/DH-Via del Podesta 1992;21x11;.jpeg')},
    {orgPath:require('./Original/DH-View of Convent Grounds 1992;19x18;.jpeg'),sm:require('./Small/DH-View of Convent Grounds 1992;19x18;.jpeg'),md:require('./Medium/DH-View of Convent Grounds 1992;19x18;.jpeg')},
    {orgPath:require('./Original/DH-Cemetery Evening 198_;18x14;.jpeg'),sm:require('./Small/DH-Cemetery Evening 198_;18x14;.jpeg'),md:require('./Medium/DH-Cemetery Evening 198_;18x14;.jpeg')},
    {orgPath:require('./Original/DH-Forest 1992;12x9;.jpeg'),sm:require('./Small/DH-Forest 1992;12x9;.jpeg'),md:require('./Medium/DH-Forest 1992;12x9;.jpeg')},
    {orgPath:require('./Original/DH-Loro Ciuffenna 1992;17x11;.jpeg'),sm:require('./Small/DH-Loro Ciuffenna 1992;17x11;.jpeg'),md:require('./Medium/DH-Loro Ciuffenna 1992;17x11;.jpeg')},
  ]
export default DrawingsFull