export const EarlyWork = [
    require('./DH New England Kitchen;20x16;.jpeg'),
    require('./DH Dining Room;16x14;.jpeg'),
    require('./DH Studio Sink;17x16;.jpeg'),
    require('./DH Swedish Bedroom;22x18;.jpeg'),
    require('./DH Swedish Entryway;12x16;.jpeg'),
    require('./DH The Empty House;50x40;.jpg'),
    require('./DH Hat Shop;45x52;.jpg'),
    require('./DH Fish Stall;45x55;.jpeg'),
    require('./DH Model in Chef$s Toque;12x16;.jpeg'),
    require('./DH Pink Bandeau;12x16;.jpeg'),
    require('./DH Self Portrait 1985;12x16;.jpeg'),
    require('./DH Cousin Robin;12x18;.jpeg'),
    require('./DH Neighborhood Printer;20x22;.jpeg'),
    require('./DH Young Man of the Seventies;15x23;.jpeg'),
    require('./DH Naples from the Corso V. Emmanuele ^1;15.5x8.5;.jpeg'),
    require('./DH Naples from the Corso V. Emmanuele ^2;14x8.5;.jpeg'),
    require('./DH Bread Street, Philadelphia;10x14;.jpeg'),
    require('./DH Block, Philadelphia;8x8;.jpeg'),
    require('./DH Boulder;16x20;.jpg'),
    require('./DH Large Boulder;44x60;.jpg'),
]
export default EarlyWork