export const SmallAbstractionsFull = [
    require('./DH9;18x18.5; - Copy.jpeg'),
    require('./DH14;10x10; - Copy.jpeg'),
    require('./DH10;18x18; - Copy.jpg'),
    require('./DH13;10.5x9.5; - Copy.jpg'),
    require('./DH17;19x26; - Copy.jpeg'),
    require('./DH7;11.5x18.5; - Copy.jpg'),
    require('./DH6;14.5x19; - Copy.jpeg'),
    require('./DH11;18.5x13; - Copy.jpg'),
]
export default SmallAbstractionsFull