export const SmallAbstractions = [
    require('./DH9;18x18.5;.jpeg'),
    require('./DH14;10x10;.jpeg'),
    require('./DH10;18x18;.jpg'),
    require('./DH13;10.5x9.5;.jpg'),
    require('./DH17;19x26;.jpeg'),
    require('./DH7;11.5x18.5;.jpg'),
    require('./DH6;14.5x19;.jpeg'),
    require('./DH11;18.5x13;.jpg'),
]
export default SmallAbstractions