export const EarlyWorkFull = [
    require('./DH New England Kitchen;20x16; - Copy.jpeg'),
    require('./DH Dining Room;16x14; - Copy.jpeg'),
    require('./DH Studio Sink;17x16; - Copy.jpeg'),
    require('./DH Swedish Bedroom;22x18; - Copy.jpeg'),
    require('./DH Swedish Entryway;12x16; - Copy.jpeg'),
    require('./DH The Empty House;50x40; - Copy.jpg'),
    require('./DH Hat Shop;45x52; - Copy.jpg'),
    require('./DH Fish Stall;45x55; - Copy.jpeg'),
    require('./DH Model in Chef$s Toque;12x16; - Copy.jpeg'),
    require('./DH Pink Bandeau;12x16; - Copy.jpeg'),
    require('./DH Self Portrait 1985;12x16; - Copy.jpeg'),
    require('./DH Cousin Robin;12x18; - Copy.jpeg'),
    require('./DH Neighborhood Printer;20x22; - Copy.jpeg'),
    require('./DH Young Man of the Seventies;15x23; - Copy.jpeg'),
    require('./DH Naples from the Corso V. Emmanuele ^1;15.5x8.5; - Copy.jpeg'),
    require('./DH Naples from the Corso V. Emmanuele ^2;14x8.5; - Copy.jpeg'),
    require('./DH Bread Street, Philadelphia;10x14; - Copy.jpeg'),
    require('./DH Block, Philadelphia;8x8; - Copy.jpeg'),
    require('./DH Boulder;16x20; - Copy.jpg'),
    require('./DH Large Boulder;44x60; - Copy.jpg'),
]
export default EarlyWorkFull